import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import PopUpcodeBarPay from '../SaleCxc/Popupcodebar';

import { cxcService } from '../../Services/cxcService';

import { Common }from '../../Utils/Common'

const SaleCxcEdit = () => {    
    const auth = Common.getUser();    
    const { id } = useParams();

    moment.locale("mx");

    const [stateSeenPopup, setstateSeenPopup] = useState(false);
    const [stateSaleSelected, setStateSaleSelected] = useState({
        clienteId:0,
        comercioId: 0,
        cxcList: null,descripcion: '',
        email: '',       
        estatusVentaId: 0,        
        fechaVta: '',
        montoAdelanto: 0,        
        montoVenta: 0,
        nombre: '',
        nombreComercial: '',        
        numCxc: 0,       
        numCxcPagadas: 0,
        ssoId: 0,
        telefono: '',
        totalPagos: 0,
        ventaId: 0
    });
    const [stateCxcs, setStateCxcs] = useState([]);

    const [statecxcidselected, setstatecxcidselected] = useState(0);
    const [stateimpselected, setstateimpselected] = useState(0);
    const [stateIdPayselected, setstateIdPayselected] = useState(0);

    const [stateNextPayment, setStateNextPayment] = useState({
        fechaVencimiento: new Date, id: 0, identificador: 0,notasCxc: "",
        numfactura: "0",pagos: 0,saldo: 0,valor: 0,
        ventaId: 0,
        visibleAlPago: false
            });
    
    useEffect(() => {
        cxcService.getByBuyertId(auth.id).then( res => {            
            const vta = res.data.find(f => f.ventaId === +id);
            setStateSaleSelected(vta);   
            
            cxcService.getByBusinessVentaDateD(id).then(res => {              
                setStateCxcs(res.data);            
                
                const nextPayment = res.data.find(f => f.visibleAlPago && vta.estatusVentaId == 1);
                if(nextPayment != undefined) {
                    setStateNextPayment(nextPayment);                
                } else {
                    setStateNextPayment({
                        fechaVencimiento: new Date, id: 0, identificador: 0,notasCxc: "",
                        numfactura: "0",pagos: 0,saldo: 0,valor: 0,
                        ventaId: 0,
                        visibleAlPago: false
                            })
                }                
            });   
        });
        
    }, []);

    const togglePop = (option) => {
        setstateSeenPopup(!stateSeenPopup);    
    };

    const onClickbtnPay = (e, cxcid, importe, idPay) => {
        e.preventDefault();       

        setstatecxcidselected(cxcid);
        setstateimpselected(importe);
        setstateIdPayselected(idPay);
        setstateSeenPopup(true);
    }

    return (
        <>
           <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <hr></hr>
                        <h1>Informaciòn de venta</h1>
                        <div style={{fontWeight: "bold"}}>
                            <div className='row'>
                                <div className='col'>   
                                    Venta : {stateSaleSelected?.ventaId}   <br></br>
                                    Fecha : {stateSaleSelected?.fechaVta} <br></br>
                                    Sucursal : {stateSaleSelected?.nombreComercial}  <br></br>
                                    Cliente : {stateSaleSelected?.nombre} <br></br>
                                    Descripciòn : {stateSaleSelected?.descripcion} 
                                </div>
                                <div className='col'>
                                    Monto:             {Common.currencyFormat(stateSaleSelected.montoVenta)} <br></br>
                                    Monto adelantado   {Common.currencyFormat(stateSaleSelected.montoAdelanto)} <br></br>
                                    Total de pagos     {Common.currencyFormat(stateSaleSelected.totalPagos)} <br></br>      
                                    Saldo              {Common.currencyFormat(stateSaleSelected.montoVenta - (stateSaleSelected.montoAdelanto + stateSaleSelected.totalPagos))}
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <h2>Proxima cuenta por pagar</h2>
                        <div className='row'>
                            <div className='alert alert-success' role="alert" style={{textAlign: 'center'}}>                 
                                   <p>Vencimiento: {moment(stateNextPayment.fechaVencimiento).format('MMMM DD YYYY')}</p>
                                    <p>Valor: {Common.currencyFormat(stateNextPayment.valor)}</p>
                                    <p>Pagos: {Common.currencyFormat(stateNextPayment.pagos)}</p>
                                    <p>Saldo: {Common.currencyFormat(stateNextPayment.saldo)}</p>
                                    {
                                        (stateNextPayment.visibleAlPago && stateSaleSelected.estatusVentaId == 1) 
                                        ? <button type="button" className="btn btn-warning" onClick={e => onClickbtnPay(e, stateNextPayment.id, stateNextPayment.valor, stateNextPayment.identificador)}>Realizar pago</button>
                                        : null
                                    }
                            </div>
                        </div>
                        <hr></hr>
                        <h2>Informaciòn de cuentas por pagar</h2>
                        <ul className="list-group">
                            {
                                stateCxcs && stateCxcs?.map(item =>
                                    <li className="list-group-item" key={item.id}>
                                        <div className='row'>
                                            <div className='col'>
                                                <p>Vencimiento: {moment(item.fechaVencimiento).format('MM/DD/YYYY')}</p>
                                                {/* <p>Factura: {item.numFactura}</p> */}
                                                <p>Valor: {Common.currencyFormat(item.valor)}</p>
                                                {/* <p>Notas: {item.notas}</p> */}
                                                <p>Pagos: {Common.currencyFormat(item.pagos)}</p>
                                                <p>Saldo: {Common.currencyFormat(item.saldo)}</p>
                                            </div>
                                            <div className='col'>
                                                <ul style={{ marginTop: "30px" }}>
                                                    {
                                                        item.cxcPagos.map(item1 => 
                                                            <li key={item1.cxcPagoId}>{moment(item1.fechaExpedicion).format('MM/DD/YYYY')} 
                                                            | {Common.currencyFormat(item1.monto)} | abono aplicado</li>    
                                                        )
                                                    }
                                                </ul>
                                                {
                                                    (item.visibleAlPago && stateSaleSelected.estatusVentaId == 1) ? 
                                                    <button type="button" className="btn btn-warning" onClick={e => onClickbtnPay(e, item.id, item.valor, item.identificador)}>Realizar pago</button> :
                                                    null
                                                }                                                
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                            
                        </ul>
                        {/* <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Id</th>                                                            
                                    <th scope="col">Fecha de Vencimiento</th> 
                                    <th scope="col">Factura</th> 
                                    <th scope="col">Valor</th>                            
                                    <th scope="col">Notas</th> 
                                    <th scope="col">Pagos</th>      
                                    <th scope="col"></th>      
                                    <th scope="col">Saldo</th>                                
                                    <th scope="col">Operaciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stateCxcs && stateCxcs?.map(item =>                                 
                                        <tr key={item.id}>
                                            <th>{item.id}</th>
                                            <th>{item.fechaVencimiento}</th>                                        
                                            <th>{item.numFactura}</th>                                        
                                            <td>{item.valor}</td>
                                            <td>{item.notas}</td>                                            
                                            <td>{item.pagos}</td>                                            
                                            <td>
                                                <ul style={{ marginTop: "30px" }}>
                                                    {
                                                    item.cxcPagos.map(item1 => 
                                                        <li key={item1.cxcPagoId}>{item1.fechaExpedicion} 
                                                        | {item1.monto} | {item1.notasPagos} | {item1.referencia01}</li>    
                                                    )
                                                    }
                                                </ul>    
                                            </td>
                                            <td>{item.saldo}</td>
                                            <td className='d-md-flex justify-content-md-end'>                                                                                        
                                                {
                                                    (item.visibleAlPago && stateSaleSelected.estatusVentaId == 1) ? 
                                                    <button type="button" className="btn btn-warning" onClick={e => onClickbtnPay(e, item.id, item.valor, item.identificador)}>Realizar pago</button> :
                                                    null
                                                }                                            
                                            </td>
                                        </tr>                                    
                                    )
                                }
                            </tbody>
                        </table>                         */}
                    </div>
                </div>
            </div>
            {stateSeenPopup ? <div> <PopUpcodeBarPay                                 
                                    cxcid={ statecxcidselected }   
                                    imp={stateimpselected}           
                                    idpay={stateIdPayselected}              
                                    togglePop={ togglePop } 
                                /> 
                            </div> : null}       

        </>
    )
}

export default SaleCxcEdit;