import axios from 'axios';
import Config from "../config.json";

const APIREST_BILBOBOLSON_URL = `${Config.REACT_APP_APIREST_BILBOBOLSON_URL}/v1/Notifications`;

const postSendResetPasswordEmail = async (values) => {

    const response = await axios.post( `${APIREST_BILBOBOLSON_URL}/SendResetPasswordBuyerEmail`, values);
    const data = await response.data;   

    return data;
}


export const notificationService = {    
    postSendResetPasswordEmail,    
}