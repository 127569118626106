import axios from 'axios';
import Config from "../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/Sales`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/Sales`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
}; 

const getAsync = async (auth_token, saleid) => { 

    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const objRequest = await axios.get(`${API_BASE_URL}/GetAsync/${saleid}`, CONFIG_REQUEST);
    const response = objRequest.data;

    return response;
}


const put  = async (auth_token, id, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.put( `${API_BASE_URL}/UpdateCancelByBuyerAsync/${id}`, values, CONFIG_REQUEST );
    const data = await response.data;       

    return data;
}

export const salesService = {
    getAsync,
    put
}