import axios from 'axios';
import Config from "../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/SecurityBuyer`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/SecurityBuyer`;

const CONFIG_REQUEST = {
    headers: { 'Content-Type': 'application/json' }
};     


const getbyEmail = async (email) => {    
    const response = await axios.get( `${API_BASE_URL}/GetByEmailAsync/${email}`, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}


const post = async (values) => {    
    const response = await axios.post( `${API_BASE_URL}/InsertAsync`, values, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const putPassword  = async (id, values) => {        

    const response = await axios.put( `${API_BASE_URL}/UpdateAsync/${id}`, values , CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

export const ssoService = {
    getbyEmail,
    post ,
    putPassword
}