import axios from 'axios';
import Config from "../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/AzureBlobStorage`;

const Download = async (auth_token, logoname) => {   

    const CONFIG_REQUEST = {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
        }
    };    

    const response = await axios.get(`${API_BASE_URL}/Download/${logoname}`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
};

export const AzureBlobStorageService = {
    Download
}