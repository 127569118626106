import React from 'react';

import { useBarcode } from 'react-barcodes';

const CodeBarUI02 = (props) => {      

    const { inputRef } = useBarcode({
        value: props.ref02,
        options: {
          background: '#FD9913',
          width: 1,
          height: 80
        }
    });

    return (
        <>
            <br></br>
            <div className="flex items-center justify-center" style={{textAlign:'center'}}>
                <svg ref={inputRef} />                                                                                                
            </div>
            <br></br>
        </>
    )

}

export default CodeBarUI02