// return the user data from the session storage
const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
   
  // return the token from the session storage
const getToken = () => {
    return sessionStorage.getItem('token') || null;
}
   
  // remove the token and user from the session storage
const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('refApartado');
  }
   
  // set the token and user from the session storage
const setUserSession = (token, user, refApartado) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('refApartado', refApartado);
}

const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const OperationTypeEnum = {
  PullApart: '1',
  PaymentA: '2',
  PaymentB: '3',
}

export const Common = {
    getUser,
    getToken,
    removeUserSession,
    setUserSession,
    currencyFormat,
    OperationTypeEnum
};