import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Common } from '../../Utils/Common';

const NavBar = () => {
    const user = Common.getUser();
    const navigate = useNavigate();
    // handle click event of logout button
    const handleLogout = () => {
        Common.removeUserSession();
        navigate('/login');
    }

    return (
        <>      
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/assets/img/logoaxify.jpg" alt="" width="100" height="50" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">                                
                                <NavLink
                                    className={({ isActive }) => 'nav-link ' + (isActive ? 'active': '')}
                                    aria-current="page"
                                    to="/"
                                    >                                        
                                        Inicio              
                                </NavLink>
                            </li>               
                            <li className="nav-item">                                
                                <NavLink
                                    className={({ isActive }) => 'nav-link ' + (isActive ? 'active': '')}
                                    aria-current="page"
                                    to="/clientEdit"
                                    >
                                        Perfil
                                </NavLink>
                            </li>        
                            <li className="nav-item">                                
                                <NavLink
                                    className={({ isActive }) => 'nav-link ' + (isActive ? 'active': '')}
                                    aria-current="page"
                                    to="/apartado"
                                    >
                                        Apartados
                                </NavLink>
                            </li>                           
                            <li className="nav-item">                                
                                <NavLink
                                    className={({ isActive }) => 'nav-link ' + (isActive ? 'active': '')}
                                    aria-current="page"
                                    to="/cxcList"
                                    >
                                        Pagos pendientes
                                </NavLink>
                            </li>                            
                        </ul>
                    </div>
                    <span>{user?.userName}</span>
                    <span>-</span>
                    <span className="navbar-text">
                        <input style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" onClick={handleLogout} value="Logout" />                      
                    </span>
                </div>
            </nav> 
         
        </>
    )
}

export default NavBar;

