
let tapiComerces = [
    {
        logo: "/assets/img/sorianaLogo.svg",
        name: "Soriana",
    },
    {
        logo: "/assets/img/farmaciasBenavidesLogo.jpg",
        name: "FarmaciasBenavides",
    },
    {
        logo: "/assets/img/waldosLogo.png",
        name: "Waldos",
    },
    {
        logo: "/assets/img/walmartLogo.png",
        name: "Walmart",
    },
    {
        logo: "/assets/img/farmaciasGuadalajaraLogo.png",
        name: "FarmaciasGuadalajara",
    },
    {
        logo: "/assets/img/extraLogo.png",
        name: "Extra",
    },
    {
        logo: "/assets/img/7elevenLogo.png",
        name: "7 Eleven",
    },
    {
        logo: "/assets/img/farmaciasAhorroLogo.png",
        name: "Farmacias Ahorro",
    },
    {
        logo: "/assets/img/chedrauiLogo.png",
        name: "Chedraui",
    }
]

export default tapiComerces