import React, { useState, useEffect} from 'react';
import { useNavigate, NavLink} from 'react-router-dom';

import {ssoService} from '../../Services/ssoService';

import base64 from 'react-native-base64'

const ChangeYourPassword = () => {   
    const navigate =  useNavigate();    

    const [stateCompany, setstateCompany] = useState({
        businessName: ''
    });
    const [statePasswordA, setstatePasswordA] = useState('');
    const [statePasswordB, setstatePasswordB] = useState('');

    const [statedisabledBtnSave, setstatedisabledBtnSave] = useState(false);
    const [stateShowValidPassword, setstateShowValidPassword] = useState(false);

    useEffect(() => {
        const refReserPass = sessionStorage.getItem('refChangePass');
        const decText = base64.decode(refReserPass); 

        ssoService.getbyEmail(decText).then(response => {
            setstateCompany(response.data)
        });
    }, []);

    const onChangehandleChangePasswordA = (e) => {
        setstatePasswordA(e.target.value);        
    }

    const onChangehandleChangePasswordB = (e) => {
        setstatePasswordB(e.target.value);        
    }

    const onclickDOMEventBtnsubmit = (e) => {
        e.preventDefault();     

        const validtoSave = passwordABValidation();
        
        if (validtoSave) {            
            
            stateCompany.password = statePasswordB; 

            ssoService.putPassword(stateCompany.id, stateCompany).then(response => {
                if (response.isSuccess){
                    // Redireccionar
                    navigate('/login', { replace: true });
                }
            });
        }
    }

    const passwordABValidation = () => {

        let validToUpdate = false;

        if (statePasswordA == statePasswordB) {
            setstatedisabledBtnSave(false);
            setstateShowValidPassword(false);
            validToUpdate = true;
        } else {
            setstatedisabledBtnSave(false);
            setstateShowValidPassword(true);
            validToUpdate = false;
        }

        return validToUpdate;
    }

    const onclickBtnHideAlertInvalidPass = ()=> {
        setstateShowValidPassword(false);
    }
  
  return (    
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container rounded border border-primary">        
        <form>
          <fieldset>          
            <legend><img src="/assets/img/logoaxify.jpg" alt="Logo axify" style={{ "width": "200px", "padding": "2px", "marginLeft": "40%" }}/></legend>
            <div className="row align-items-md-stretch">
              <div className="col-md-12">
                <div className="h-100 p-5 bg-light border rounded-3" style={{ "textAlign": "center" }}>
                  <h2>Bienvenido/a</h2>
                  <div className="mb-3">                    
                    <input 
                        type="password" 
                        className="form-control input-lg" 
                        id="PasswordA" 
                        name="PasswordA"
                        aria-describedby="nameHelp" 
                        placeholder="Password" 
                        onChange={e => onChangehandleChangePasswordA(e)}
                    />
                  </div>
                  <div className="mb-3">
                    <input 
                        type="password" 
                        className="form-control input-lg" 
                        id="PasswordB" 
                        name="PasswordB"
                        aria-describedby="nameHelp" 
                        placeholder="Confirmar Password" 
                        onChange={e => onChangehandleChangePasswordB(e)}
                    />
                  </div>
                  <div className='mb-3'>                  
                    {                                        
                            stateShowValidPassword ? 
                            <div className="alert alert-warning" role="alert">
                                Password <strong>Incorrecto!</strong>.
                                <button className='btn' onClick={e => onclickBtnHideAlertInvalidPass(e)}>X</button>
                            </div>                     
                            : null                                        
                    }                                       
                  </div>
                  <div className="mb-3">                    
                    <input type="button" className="btn btn-primary" style={{ "width": "100%" }} 
                    value="Enviar" onClick={e => onclickDOMEventBtnsubmit(e)} /><br />                    
                  </div>                 
                  <div className="mb-3" style={{ "textAlign": "center" }}>                    
                    <NavLink
                        className={({ isActive }) => 'nav-link text-blue' + (isActive ? 'active': '')}
                        aria-current="page"
                        to="/login"
                        >
                            Iniciar sesion             
                    </NavLink> 
                  </div>                        
                </div>                
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
}

export default ChangeYourPassword;