import React from 'react'

import './App.css';

import { AppRouter } from './Routers/AppRouter';

export const  App = () => {

  return (    
    <AppRouter></AppRouter>
  )  
}


