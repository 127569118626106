 import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Common } from '../../Utils/Common';
///import CodeBarUI01 from './codebar01';
import CodeBarUI02 from './codebar02';

import base64 from 'react-native-base64';

/* ES6 */
import * as htmlToImage from 'html-to-image';
import FileSaver from 'file-saver';

import { pullapartRefService } from '../../Services/pullApartReference';
import { commerceService} from '../../Services/commerceService';
import { commerceProductService } from '../../Services/commerceProductservice';
import { IpifyService } from '../../Services/api_ipify_org';

import { companyService } from '../../Services/companyService';
import { AzureBlobStorageService } from '../../Services/azureBlobStorageService';

const PaulledApartEdit = () => {  
    const navigate =  useNavigate();     
    const token = Common.getToken(); 
    const auth = Common.getUser();  

    const showValueA = 'accordion-collapse collapse show';
    const showValueB = 'accordion-collapse collapse';

    const [stateCollapseValuetabA, setstateCollapseValuetabA] = useState(showValueA);
    const [stateCollapseValuetabB, setstateCollapseValuetabB] = useState(showValueB);
    const [stateCollapseValuetabC, setstateCollapseValuetabC] = useState(showValueB);
    
    const [statecodigo01, setstatecodigo01] = useState('abcdefghi');
    const [statecodigo02, setstatecodigo02] = useState('abcdefghi');

    const [stateMontoApartado, setStateMontoApartado] = useState(0);
    const [stateMontocotaMnto, setStateMontocotaMnto] = useState(0);
    const [stateMontoTotalApartado, setStateMontoTotalApartado] = useState(0);

    const [stateCommerce, setStateCommerce] = useState({
        id: 0,
        businessName: '',
        tradeName: '',
        rFC: '',
        tblBusinessTypeId: '',
        contact: '',
        phone: '',
        email: '',
        tblPasarelaPagoId: '',
        percentDeposit: 0,
        fracuencyPay: 0,        
        planPaymentDuration:0,
        ammountoCancel:0,
        companyId: 0,
        externalReferenceAntad: '',
        ssoId: 0
    });

    const [stateProductCommerce, setStateProductCommerce] = useState({
        id : 0,
        businessId : 0,
        name : '',
        description : '',
        sku : '',
        salePrice : 0
    });

    const [stateMyIp, setStateMyIp] = useState('');

    const [useStatecompany, setUsestateCompany] = useState({
        id: 0,
        name:'',
        phone:'',
        externalRefAntad:'',
        logofileName:''        
    });  

    const [imgUrl, setImgUrl] = useState();

    const [stateEnableBtnStep1, setStateEnableBtnstep1] = useState('A');
    const [stateEnableBtnStep2, setStateEnableBtnstep2] = useState('A');
    const [stateEnableBtnStep3, setStateEnableBtnstep3] = useState('A');

    const [hiddenSpinner1, sethiddenSpinner1] = useState(false);

    const myRefBtnStep1 = useRef(null);

    useEffect(() => {        
        setstatecodigo01(sessionStorage.getItem('refApartado'));

        IpifyService.getIP().then((response) =>{
            setStateMyIp(response.ip);
        });        

        setTimeout(() => {
            myRefBtnStep1.current.click();
        }, 1000);
        
    }, []);    


    const onChangeProduct = (event)=> {        
        setstatecodigo01(event.target.value);
    }

    const onKeyDownProduct = (event) => {
        if (event.keyCode == 17 || event.keyCode == 86){
            console.log('ok');
        } else {
            event.preventDefault();            
        }
    }

    const onFocusProduct = (event)=> {
        setstatecodigo01('');        
    }

    const onBlurProduct = (event)=> {
        setstatecodigo01(event.target.value);        
    }

    const onClickcontinueBtnStep1 = async (event) => {
        event.preventDefault();

        setStateEnableBtnstep1('');
        sethiddenSpinner1(true);

        if(statecodigo01 == 'null' || statecodigo01.length == 0){
            navigate('/cxcList', { replace: true });
        }

        const decText = base64.decode(statecodigo01);        

        const myArray = decText.split("|"); 
        
        const varCommerceList = await commerceService.getAsync(token, myArray[1]);
        setStateCommerce(varCommerceList.data);

        const varcommerceProduct = await commerceProductService.getAsync(token, myArray[2]);
        setStateProductCommerce(varcommerceProduct.data, myArray[1]);        

        const varcompany = await companyService.getById(token, varCommerceList.data.companyId);
        setUsestateCompany(varcompany.data);
        
        //console.log(varcompany.data);

        if (varcompany.data.logofileName != '') {
            const varLogoFile = await AzureBlobStorageService.Download(token, varcompany.data.logofileName);
            var contentArray = varLogoFile.data.contentByteArray;				
            setImgUrl(`data:image/jpeg;base64,${contentArray}`);    
        } else {
            setImgUrl(`data:image/jpeg;base64,${[]}`);    
        }

        const montoApartado = +myArray[6];
        const montoTotalApart = ((+myArray[6])+0);
        
        setStateMontoTotalApartado(montoTotalApart);        
        setStateMontoApartado(montoApartado);
        setStateMontocotaMnto(0);

        sethiddenSpinner1(false);

        setstateCollapseValuetabA(showValueB);
        setstateCollapseValuetabB(showValueA);
        setstateCollapseValuetabC(showValueB);        
    }

    const onClickcontinueBtnStep21 = (event) =>{
        event.preventDefault();

        //setstatecodigo01('');  

        setstateCollapseValuetabA(showValueA);
        setstateCollapseValuetabB(showValueB);
        setstateCollapseValuetabC(showValueB);
        
        setStateEnableBtnstep1('A');

    }

    const onClickcontinueBtnStep22 = (event) =>{
        event.preventDefault();

        setStateEnableBtnstep2('');

        const decText = base64.decode(statecodigo01);        

        const myArray = decText.split("|");     

        if(!stateCommerce || stateCommerce.id === 0 || !stateProductCommerce || stateProductCommerce.id === 0){
            Common.removeUserSession();
            navigate('/login', { replace: true });
        } else {
            pullapartRefService.post({
                Id: 0,
                Referencia: `${myArray[0]}|${myArray[1]}|${myArray[2]}|${auth.id}|${myArray[4]}|${myArray[5]}|${myArray[6]}|0`,
                IpOrigen:stateMyIp,
                Fecha: new Date,
                Estatus: true
            }).then((res) => {
                const reftocodebar = `1${res.data}`;
                setstatecodigo02(reftocodebar);      
                
                setstateCollapseValuetabA(showValueB);
                setstateCollapseValuetabB(showValueB);
                setstateCollapseValuetabC(showValueA);
            });            
        }        
    }

    const onClickcontinueBtnStep31 = (event) =>{
        event.preventDefault();

        setStateEnableBtnstep2('A');

        setStateCommerce({
            id: 0,
            businessName: '',
            tradeName: '',
            rFC: '',
            tblBusinessTypeId: '',
            contact: '',
            phone: '',
            email: '',
            tblPasarelaPagoId: '',
            percentDeposit: 0,
            fracuencyPay: 0,        
            planPaymentDuration:0,
            ammountoCancel:0,
            companyId: 0,
            externalReferenceAntad: '',
            ssoId: 0
        });
    
        setStateProductCommerce({
            id : 0,
            businessId : 0,
            name : '',
            description : '',
            sku : '',
            salePrice : 0
        });

        setStateMontoApartado(0);
        setStateMontocotaMnto(0);
        setStateMontoTotalApartado(0);

        setstateCollapseValuetabA(showValueB);
        setstateCollapseValuetabB(showValueA);
        setstateCollapseValuetabC(showValueB);
    }

    const onClickcontinueBtnStep32 = (event) =>{
        event.preventDefault();

        setStateEnableBtnstep3('A');

        htmlToImage.toBlob(document.getElementById('divCodBarUI02'))
        .then(function (blob) {
            if (window.saveAs) {
                window.saveAs(blob, `Ref_Axify_${statecodigo02}`);
            } else {
                FileSaver.saveAs(blob, `Ref_Axify_${statecodigo02}`);
            }
        });
    }

    const onClickcontinueBtnStep33 = (event) => {
        event.preventDefault();

        navigate(`/pullapartByClip/${statecodigo02}`, { replace: true });        
    }

    const onClickcontinueBtnStep34 = (event) => {
        event.preventDefault();
        navigate(`/onSitePaymentByTapi/${statecodigo02}`, { replace: true });        
    }

    return (
        <>
            <br></br>
            <br></br>            

            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Paso 1
                                </button>
                                </h2>
                                <div id="collapseOne" className={stateCollapseValuetabA} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <label htmlFor="product" className="form-label">Introduce la referencia del producto</label>
                                    <input className="form-control" placeholder="" 
                                        id="product"
                                        name="product"
                                        type="text"                                              
                                        onFocus={onFocusProduct}            
                                        onChange={onChangeProduct}                            
                                        onBlur={onBlurProduct}          
                                        onKeyDown={onKeyDownProduct}  
                                        value={statecodigo01}                                  
                                    />
                                    <br></br>
                                    <button style={{backgroundColor:'#FD9913', color:'white'}} 
                                        className="w-100 btn btn-success" 
                                        onClick={onClickcontinueBtnStep1}
                                        disabled={!stateEnableBtnStep1}
                                        ref={myRefBtnStep1}
                                    >Ir al paso siguiente</button>
                                    <br></br>
                                    <br></br>
                                    { hiddenSpinner1 &&
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    }
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" 
                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" 
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    Paso 2
                                </button>
                                </h2>
                                <div id="collapseTwo" className={stateCollapseValuetabB} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>Información para realizar el apartado.</strong>
                                    <br></br>
                                    <div className='row' style={{textAlign:'center'}}>
                                        <div className='col-6'>
                                            <img src={imgUrl} />
                                        </div>
                                        <div className='col-6'>
                                            <p><strong>Comercio:</strong> {stateCommerce.businessName}</p>
                                            <p><strong>Producto:</strong> {stateProductCommerce.name}</p>
                                            <p><strong>Precio de venta:</strong> {Common.currencyFormat(stateProductCommerce.salePrice)}</p>
                                            <p><strong>Monto total:</strong> {Common.currencyFormat(stateMontoTotalApartado)}</p>                                                                                   
                                            <p><strong>Apartado:</strong> {Common.currencyFormat(stateMontoApartado)}</p>
                                            <p><strong>Importe de servicio x transaccion:</strong> ($15.00)</p>
                                        </div>
                                        
                                    </div>                 
                                    <br></br>
                                    <div className='row'>
                                        <div className='col'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} className="w-100 btn btn-success" 
                                                onClick={onClickcontinueBtnStep21}>Ir al paso anterior</button>                        
                                        </div>
                                        <div className='col'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} 
                                                className="w-100 btn btn-success" 
                                                onClick={onClickcontinueBtnStep22}
                                                disabled={!stateEnableBtnStep2}
                                            >Ir al paso siguiente</button>          
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Paso 3
                                </button>
                                </h2>
                                <div id="collapseThree" className={stateCollapseValuetabC} data-bs-parent="#accordionExample">
                                <div className="accordion-body">                                    
                                    <div id="divCodBarUI02">
                                        <p><strong>Referencia de apartado.</strong></p>
                                        <CodeBarUI02 ref02={statecodigo02}></CodeBarUI02>
                                        <div style={{textAlign: 'center'}}>
                                            <p><strong>Monto total:</strong> {Common.currencyFormat(stateMontoTotalApartado)}</p>
                                            <p><strong>Apartado:</strong> {Common.currencyFormat(stateMontoApartado)}</p>
                                            <p><strong>Importe de servicio x transaccion:</strong> ($15.00)</p>
                                        </div>                                        
                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <div className='col'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} className="w-100 btn btn-success" 
                                                onClick={onClickcontinueBtnStep31}>Ir al paso anterior</button>                        
                                        </div>
                                        <div className='col'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} className="w-100 btn btn-success" 
                                            onClick={onClickcontinueBtnStep32}>Guardar referencía</button>          
                                        </div>
                                        <div className='col'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} className="w-100 btn btn-success" 
                                            onClick={onClickcontinueBtnStep33}>Pago con Clip</button>          
                                        </div>
                                        <div className='col'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} className="w-100 btn btn-success" 
                                            onClick={onClickcontinueBtnStep34}>Pago presencial</button>          
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </>
    )
}

export default PaulledApartEdit;

/*test*/

 // const onClickGenera = (event) => {
    //     event.preventDefault();

    //     if(statecodigo01 == 'null' || statecodigo01.length == 0){
    //         navigate('/login', { replace: true });
    //     }

    //     const decText = base64.decode(statecodigo01);        

    //     const myArray = decText.split("|");        

    //     commerceService.getAsync(token, myArray[1]).then((response) => {
    //         setStateCommerce(response.data);
    //     });

    //     commerceProductService.getAsync(token, myArray[2]).then((response) => {
    //         setStateProductCommerce(response.data);
    //     });

    //     setTimeout(() => {
    //         if(!stateProductCommerce || !stateProductCommerce){
    //             Common.removeUserSession();
    //             navigate('/login', { replace: true });
    //         } else {
    //             pullapartRefService.post({
    //                 Id: 0,
    //                 Referencia: `${myArray[0]}|${myArray[1]}|${myArray[2]}|${auth.id}|${myArray[4]}|${myArray[5]}|${myArray[6]}|0`,
    //                 Fecha: new Date,
    //                 Estatus: true
    //             }).then((res) => {
    //                 const reftocodebar = `1${res}`;
    //                 setstatecodigo02(reftocodebar);        
    //             });            
    //         }                

    //         //setstatecodigo02(`${myArray[0]}|${myArray[1]}|${myArray[2]}|${auth.id}|${myArray[4]}|${myArray[5]}|${myArray[6]}|15`);        
        
    //         const montoApartado = +myArray[6];
    //         const montoTotalApart = ((+myArray[6])+0);
            
    //         setStateMontoTotalApartado(montoTotalApart);        
    //         setStateMontoApartado(montoApartado);
    //         setStateMontocotaMnto(0);
    //     }, 1500);        
    // }