import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink} from 'react-router-dom';

import swal from 'sweetalert';
import base64 from 'react-native-base64';


import {notificationService} from '../../Services/notificationService';

const ResetYourPassword = () => {   
  const navigate = useNavigate();  
 
  const [stateEmail, setStateEmail] = useState('');
  
  const onChangeDOMEvent_inputUser = (value) => {
    setStateEmail(value)
  }


  const onClickDOMEvent_btnI = async (e) => {
    e.preventDefault();

    const encText = base64.encode(stateEmail);         

    const ciphertext = `${window.location.origin}?refChangePass=${encText}`;          

    var request = {
        email:   stateEmail,
        link:  ciphertext,
        dateHourCreate: new Date 
    }

    notificationService.postSendResetPasswordEmail(request);
    swal("Correo enviado!", "Se envio el correo con el link de cambio de password!", "success")
    .then((value) => {
        navigate('/login', { replace: true });
    });        
  }


  return (    
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container rounded border border-primary">        
        <form>
          <fieldset>          
            <legend><img src="/assets/img/logoaxify.jpg" alt="Logo AppPaguitos" style={{ "width": "200px", "padding": "2px", "marginLeft": "40%" }}/></legend>
            <div className="row align-items-md-stretch">
              <div className="col-md-12">
                <div className="h-100 p-5 bg-light border rounded-3" style={{ "textAlign": "center" }}>
                  <h2>Olvidaste tu password?</h2>
                  <h5>Escribe tu correo</h5>
                  <div className="mb-3">
                    <input type="text" className="form-control"                      
                     name="email"                      
                     onChange={(e)=>onChangeDOMEvent_inputUser(e.target.value)}/>
                  </div>                  
                  <div className="mb-3">                    
                    <input type="button" className="btn btn-primary" style={{ "width": "100%" , "textAlign": "center"}}                       
                      onClick={e => onClickDOMEvent_btnI(e)} value="Enviar"/><br />                    
                  </div>                 
                  <div className="mb-3" style={{ "textAlign": "center" }}>                    
                    <NavLink
                        className={({ isActive }) => 'nav-link text-blue' + (isActive ? 'active': '')}
                        aria-current="page"
                        to="/login"
                        >
                            Iniciar sesion             
                    </NavLink> 
                  </div>                                   
                </div>                
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
}

export default ResetYourPassword;