import axios from 'axios';
import Config from "../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/TokenBuyer/Authenticate`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/TokenBuyer/Authenticate`;

const CONFIG_REQUEST = {
    headers: { 'Content-Type': 'application/json' }
};     

const Authentication = async (values) => {
    const response = await axios.post( API_BASE_URL , values, CONFIG_REQUEST);   

    return response;
}

export const tokenService = {
    Authentication 
}