import axios from 'axios';
import Config from "../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_CXC_URL}/Cxc`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_CXC_URL}/v1/Cxc`;

const getByIdWithBalance = async (cxcid) => {
    const objRequest = await axios.get(`${API_BASE_URL}/ByIdWithBalance/${cxcid}`);
    const response = objRequest.data;

    return response;
}

const getByBuyertId = async (buyerclientid) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByBuyerIdH/${buyerclientid}`);
    const response = objRequest.data;

    return response;
}

const getByBusinessVentaDateD = async (ventaid) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByBusinesidVentaidDateD/${ventaid}`);
    const response = objRequest.data;

    return response;
}

export const cxcService = {   
    getByIdWithBalance,
    getByBuyertId,
    getByBusinessVentaDateD
}