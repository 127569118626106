import React from 'react'

const Dashboard = () => {    
 
    return (
        <div>
            Welcome User!<br /><br />
        
        </div>
    );
};

export default Dashboard;