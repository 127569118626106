import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { clientService } from '../../Services/clientService';

import { Common } from '../../Utils/Common';

import swal from 'sweetalert';

const ClientEdit = () => {
    const auth = Common.getUser();  
        
    const navigate =  useNavigate();   

    const [stateClient, setStateClient] = useState({
        id: 0,
        name: '',
        email: 0,
        phone: '',
        ssoId: auth.id,
        numberAddress: '',
        postalCodeAddress: '',
        streetAddress: ''
    });    
    
    useEffect(() => {
        console.log(auth);   
        
        setStateClient(auth);

        // clientService.getById(auth?.token, auth.id).then(response => {           
        //     setStateClient(response.data);           
        //     console.log();
        // });      
    }, []);

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateClient((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onClickbtnSaveUpdate = () => {       
        clientService.put(auth.token,stateClient.id, stateClient).then(response => {
            swal("Actualización exitosa!", "tu información ha sido actualizada!", "success")
            .then((value) => {
                // Redireccionar
                navigate('/login', { replace: true });
            }); 
        }).catch(error => {
            setLoading(false);
            console.log(error.response);

            setErrorL(error);      
        });
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/home', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Editar Cliente</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>                
                <h5>Información general</h5>
                <hr></hr>                                    
                <div className="row">
                    <div className="col">
                        <label htmlFor="userName" className="form-label">Nombre</label>
                        <input className="form-control" placeholder="" required="" disabled
                            id="userName"
                            name="userName"
                            type="text"                            
                            value={stateClient.userName}            
                        />                                  
                    </div>
                    <div className="col">
                        <label htmlFor="user" className="form-label">Email</label>
                        <input className="form-control" placeholder="" required="" disabled
                            id="user"
                            name="user"
                            type="text"                            
                            value={stateClient.user}              
                        />
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="phone" className="form-label">Telefono</label>
                        <input className="form-control" placeholder="" required="" 
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.phone}              
                        />                                    
                    </div>
                    <div className="col">                    
                    </div>
                </div>
                <br></br>
                <h5>Dirección</h5>                
                <hr></hr>
                <div className="row">
                    <div className="col">
                        <label htmlFor="numberAddress" className="form-label">Número</label>
                        <input className="form-control" placeholder="" required="" 
                            id="numberAddress"
                            name="numberAddress"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.numberAddress}              
                        />                                    
                    </div>
                    <div className="col">    
                        <label htmlFor="postalCodeAddress" className="form-label">Código postal</label>
                        <input className="form-control" placeholder="" required="" 
                            id="postalCodeAddress"
                            name="postalCodeAddress"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.postalCodeAddress}              
                        />      
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="streetAddress" className="form-label">Calle</label>
                        <input className="form-control" placeholder="" required="" 
                            id="streetAddress"
                            name="streetAddress"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.streetAddress}              
                        />                                    
                    </div>
                    <div className="col">    
                          
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" onClick={e => onClickbtnSaveUpdate(e)}>Actualizar información</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" className="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>
            </div>                   
        </>
    )
}

export default ClientEdit;