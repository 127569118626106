import React from 'react'
import { BrowserRouter, Routes , Route } from 'react-router-dom';

import Login from '../Components/Login/Login';
import BuyerRegister from '../Components/Buyer/BuyerRegister';
import ResetYourPassword from '../Components/Login/ResetYourPassword';
import ChangeYourPassword from '../Components/Login/ChangeYourPassword';

import DashboardRouter from './DashboardRouter';

export const AppRouter = () => {
  
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                
                <Route exact path="/buyerRegister" element={<BuyerRegister />}></Route>
                <Route exact path="/resetyourpassword" element={<ResetYourPassword />}></Route>
                <Route exact path="/changeyourpassword" element={<ChangeYourPassword />}></Route>                

                <Route exact path="/*" element={<DashboardRouter />}></Route>
            </Routes>
        </BrowserRouter>
    );
}


