import React, { useState } from 'react';
import { useNavigate , NavLink} from 'react-router-dom';

import { ssoService } from '../../Services/ssoService';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';

const BuyerRegister = () => {

    const navigate = useNavigate();

    const [errorL, setErrorL] = useState(null);
    const [loading, setLoading] = useState(false);

    const [stateEmailValid, setstateEmailValid] = useState(false);

     // Pass the useFormik() hook initial form values and a submit function that will
    // be called when the form is submitted
    const formik = useFormik({
    initialValues: {
        user: '',
        userName: '',        
        phone: '',
        password: ''            
    },
    validationSchema: Yup.object({
        user: Yup.string()
            .min(4, 'El nombre no puede ser menor a 4 caracteres')
            .required('La descripcion es obligatorio') 
    }),
    onSubmit: values => {
        if (stateEmailValid) {
          ssoService.post(values).then( response => {
              swal("Registro exitoso!", "Cuenta creada!", "success")
              .then((value) => {
                setLoading(false);              
                navigate('/login');
              });  
            }).catch(error => {
              setLoading(false);
              console.log(error.response);

              setErrorL(error);

            //if (error.response.status === 401) setError(error.response.data.message);
            //else setError("Something went wrong. Please try again later.");
          });
        } else {
          //alert("El Email ya existe!");
          swal("Registro no exitoso!", "El Email ya existe!", "warning")
              .then((value) => {
                setLoading(false);              
                navigate('/login');
              }); 
        }
    }, });

    const onBlur_inputEmail = (e) => {
      
      ssoService.getbyEmail(e.target.value).then( response => {
        if (response.data != null){
          if(response.data.id > 0){
            //alert("El Email ya existe!");
            swal("Busqueda de información!", "El Email ya existe!", "warning")
              .then((value) => {
                setstateEmailValid(false);               
              });            
          } else {
            setstateEmailValid(true);
          }  
        } else {
          setstateEmailValid(true);
        }
      });
    }
  
    // const onClickDOMEvent_btnC = (e) => {
    //   e.preventDefault();
    //   navigate('/login');
    // }

    return (
        <>
        <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container rounded border border-primary">        
        <form onSubmit={formik.handleSubmit}>
          <fieldset>          
            <legend><img src="/assets/img/logoaxify.jpg" alt="Logo AppPaguitos" style={{ "width": "200px", "padding": "2px", "marginLeft": "43%" }}/></legend>
            <div className="row align-items-md-stretch">
              <div className="col-md-6">
                <div className="h-100 p-5 bg-light border rounded-3">
                  <ul>                    
                    <li><h5>Crea tu cuenta para pagar tus abonos aquí.</h5></li>
                    <li><h5>Después toma foto al código QR del producto/servicio que vas a pagar a plazos. </h5></li>
                    <li><h5>A continuación, inicia sesión para hacer el pago.</h5></li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="h-100 p-5 bg-light border rounded-3" style={{ "textAlign": "center" }}>                  
                  <div className='row'>
                      <div className='col'>
                        <h2>Crear mi cuenta</h2>
                      </div>                      
                  </div>                                    
                  <div className='row'>                      
                      <div className='col' style={{ marginTop: "10px", textAlign: "center" }}>
                        O bien, <span><NavLink aria-current="page" to="/"> INICIAR SESIÓN</NavLink></span>
                      </div>
                  </div>
                  <hr></hr>
                  <div className="mb-3">
                    <label htmlFor="userName" className="form-label">Nombre de usuario</label>
                    <input type="text" className="form-control"                         
                        name="userName" 
                        id="userName"
                        onChange={formik.handleChange}
                        value={formik.values.userName}            
                        disabled={loading}
                    />
                    {formik.errors.userName ? <div className="invalid-feedback">{formik.errors.userName}</div> : null}
                  </div>                  
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Teléfono</label>
                    <input type="text" className="form-control"                         
                        name="phone" 
                        id="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}            
                        disabled={loading}
                    />
                    {formik.errors.phone ? <div className="invalid-feedback">{formik.errors.phone}</div> : null}
                  </div>                
                  <div className="mb-3">
                    <label htmlFor="user" className="form-label">Email</label>
                    <input type="text" className="form-control"                         
                        name="user"
                        id="user"      
                        onChange={formik.handleChange}
                        onBlur={ e => onBlur_inputEmail(e) }
                        value={formik.values.user}            
                        disabled={loading}
                     />
                     {formik.errors.user ? <div className="invalid-feedback">{formik.errors.user}</div> : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control"                         
                        name="password" 
                        id="password"
                        onChange={formik.handleChange}
                        value= {formik.values.password}
                        disabled={loading}
                    />
                    {formik.errors.user ? <div className="invalid-feedback">{formik.errors.user}</div> : null}
                  </div>
                  <div className="mb-3">                    
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                    <label class="form-check-label" for="flexCheckChecked">
                        Acepto los términos y condiciones y el aviso de privacidad.
                    </label>
                  </div>                
                  
                  <div className="mb-3">
                    {errorL && <><small style={{ color: 'red' }}>{errorL}</small><br /></>}<br />
                    <input type="submit" className="btn btn-primary" style={{ "width": "100%" }} value={loading ? 'Loading...' : 'Crear'} disabled={loading} /><br /> 
                  </div>                 
                </div>                
              </div>
            </div>
          </fieldset>
        </form>
      </div>

        </>
    );
}

export default BuyerRegister;
