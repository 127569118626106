import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_FINTECHS_URL}/v1/Tapi`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
}; 

const generateReference = async (auth_token_tapifintech, data) => { 

    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token_tapifintech}`;

    const objRequest = await axios.post(`${API_BASE_URL}/GenerateReference`, data, CONFIG_REQUEST);
    const response = objRequest.data;
    console.log(response)
    return response;
}

export const tapiService = {
    generateReference,
}