import React, { useState, useEffect} from 'react'
import {  Routes , Route , useLocation, useNavigate} from 'react-router-dom';

import PrivateRoute from '../Utils/PrivateRoute';
import { Common } from '../Utils/Common';

import NavBar from '../Components/Home/NavBar'

import Dashboard from '../Components/Dashboard/Dashboard';
import Home from '../Components/Home/Home';

import PulledApart from '../Components/PulledApart/pulledapart';
import PullapartByClip from '../Components/PulledApart/PullApartByClip';
import ThreeDSByClipPA from '../Components/PulledApart/ThreeDSByClipPA';
import CxcList from '../Components/Cxc/CxcList';
import SaleCxcEdit from '../Components/SaleCxc/SaleCxcEdit';

import PaymentByClib from '../Components/SaleCxc/PaymentsByclib';
import ThreeDSByclib from '../Components/SaleCxc/ThreeDSByclib';
import ClientEdit from '../Components/Client/ClientEdit';

import OnSitePaymentByTapi from "../Components/OnSitePayment/OnSitePaymentByTapi"

const DashboardRouter = () => {
    const navigate = useNavigate();  
    const [authLoading, setAuthLoading] = useState(true);
    const auth = Common.getUser(); 
  
    const search = useLocation().search;
    const queryParameters = new URLSearchParams(search);
    const refApartado = queryParameters.get("ref");  
    const refChangePass = queryParameters.get("refChangePass"); 
        
    //console.log(refApartado);

    localStorage.setItem('refApartado', refApartado);      
    
    useEffect(() => {        
      if(refChangePass != null) {
        sessionStorage.setItem('refChangePass', refChangePass);       
        navigate('/changeyourpassword', { replace: true });
      }  
      if (!auth) {
        Common.removeUserSession();
        setAuthLoading(false);
        return;
      } else {      
        setAuthLoading(false);
      } 
    }, []);
   
    if (authLoading && auth) {
      return <div className="content">Checking Authentication...</div>
    }

    return (        
        <>
            <NavBar></NavBar>
            <Routes>
                <Route path='/dashboard' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                    <Dashboard />
                    </PrivateRoute>
                }                
                />
                <Route path='/clientEdit' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <ClientEdit />
                    </PrivateRoute>
                }
                />                            
                <Route path='/apartado' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <PulledApart />
                    </PrivateRoute>
                }              
                />
                <Route path='/pullapartByClip/:id' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <PullapartByClip />
                    </PrivateRoute>
                }              
                />
                <Route path='/onSitePaymentByTapi/:id' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <OnSitePaymentByTapi />
                    </PrivateRoute>
                }              
                />
                <Route path='/threeDSByclipPA/:id/:bussinessid/:saldo' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <ThreeDSByClipPA />
                    </PrivateRoute>
                }                
                />
                <Route path='/cxcList' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <CxcList />
                    </PrivateRoute>
                }
                />
                <Route path='/saleCxcList/:id' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <SaleCxcEdit />
                    </PrivateRoute>
                }                
                />
                <Route path='/paymentByClib/:id/:cxcid' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <PaymentByClib />
                    </PrivateRoute>
                }                
                />
                <Route path='/threeDSByclib/:id/:cxcid/:bussinessid/:saldo' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <ThreeDSByclib />
                    </PrivateRoute>
                }                
                />
                <Route path='/' element={
                    <PrivateRoute currentUser={auth} redirectTo='/login'>
                      <Home />
                    </PrivateRoute>
                }
                />  
            </Routes>        
        </>
    );
}


export default DashboardRouter;