import React from 'react';
import { Navigate } from 'react-router-dom';

 
// handle the private routes
const PrivateRoute = ({ currentUser, children, redirectTo }) => {
  if (!currentUser) return <Navigate to={redirectTo} replace />;
  return children;
};

export default PrivateRoute;

