import axios from 'axios';
import Config from "../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/BusinessProduct`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/BusinessProduct`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
}; 

const getAsync = async (auth_token_sauron, productid) => { 

    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token_sauron}`;

    const objRequest = await axios.get(`${API_BASE_URL}/GetAsync/${productid}`, CONFIG_REQUEST);
    const response = objRequest.data;

    return response;
}


export const commerceProductService = {
    getAsync
}