import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';

import { tokenService } from '../../Services/tokenService';
import { Common } from '../../Utils/Common';

const Login = () => {   
  const navigate = useNavigate();  
  const search = useLocation().search;
  //console.log(localStorage.getItem('refApartado'));

  const [stateEmail, setStateEmail] = useState('');
  const [statePassword, setStatePassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [stateShowValidUser, setstateShowValidUser] = useState(false);

  const onChangeDOMEvent_inputUser = (value) => {
    setStateEmail(value)
  }

  const onChangeDOMEvent_inputPassword = (value) => {
    setStatePassword(value)
  }

  const onClickDOMEvent_btnI = async (e) => {
    e.preventDefault();

    setError(null);
    setLoading(true);

    const { status, data } = await tokenService.Authentication({user: stateEmail, password: statePassword});

    //console.log(status);
    //console.log(data);
    
    if(status == 200) {       
      setLoading(false);      
      Common.setUserSession(data.data.token, data.data, localStorage.getItem('refApartado'));            
      navigate('/');               
    } else {     
      setstateShowValidUser(true);
    } 
  }

  const onClickaShowRegisterForm = (e) => {
    e.preventDefault();
    navigate('/BuyerRegister');
  }
  
  const onClickaShowResetYourPassword = (e) => {
    e.preventDefault();
    navigate('/resetyourpassword');
  }
  
  const onclickBtnHideAlertUserNotExist = (e) => {
    e.preventDefault()

    setstateShowValidUser(false);
  }

  return (    
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container rounded border border-primary">        
        <form>
          <fieldset>          
            <legend><img src="/assets/img/logoaxify.jpg" alt="Logo Axify" style={{ "width": "200px", "padding": "2px", "marginLeft": "40%" }}/></legend>
            <div className="row align-items-md-stretch">
              <div className="col-md-12">
                <div className="h-100 p-5 bg-light border rounded-3" style={{ "textAlign": "center" }}>
                  <h2>Bienvenido/a</h2>
                  <div className='mb-3'>
                    {
                      stateShowValidUser ? 
                      <div className="alert alert-warning" role="alert">
                        Correo o Password <strong>Incorrecto!</strong>.
                        <button className='btn' onClick={e => onclickBtnHideAlertUserNotExist(e)}>X</button>
                      </div>                     
                      : null
                    }
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Correo</label>
                    <input type="text" className="form-control"                      
                     name="email"                      
                     onChange={(e)=>onChangeDOMEvent_inputUser(e.target.value)}                     
                     />
                  </div>                  
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control"                     
                    name="password" 
                    id="password"
                    onChange={(e)=>onChangeDOMEvent_inputPassword(e.target.value)}                    
                    />
                  </div>
                  <div className="mb-3">
                    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    <input type="button" className="btn btn-primary" style={{ "width": "100%" }} value='Login' onClick={e => onClickDOMEvent_btnI(e)} /><br />                    
                  </div>                 
                  <div className="mb-3" style={{ "textAlign": "center" }}>                    
                    <span >¿Todavía no tienes cuenta? <a style={{ "coursor": "pointer"}} className='register-form-link' onClick={e => onClickaShowRegisterForm(e)}>Regístrate de forma gratuita</a></span>
                  </div>      
                  <div className="mb-3" style={{ "textAlign": "center" }}>                    
                    <a style={{ "coursor": "pointer"}} className='register-form-link' onClick={e => onClickaShowResetYourPassword(e)}>¿Se te olvidó tu password?</a>
                  </div>                               
                </div>                
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
}

export default Login;