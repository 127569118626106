import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { cxcService } from '../../Services/cxcService';
import { salesService } from '../../Services/salesService';
import { commerceService } from '../../Services/commerceService';
import { clibUserService } from '../../Services/Fintechs/clibUserService';
import { clibService } from '../../Services/Fintechs/clibService';
import { boromirUserService } from '../../Services/Boromir/boromirUserService';
import { boromirService } from '../../Services/Boromir/boromirService';

import { Common } from '../../Utils/Common';

import swal from 'sweetalert';

import { pullapartRefService } from '../../Services/pullApartReference';
import { array } from 'yup';

import tapiCommerces from '../PulledApart/tapiCommerces';
import { tapiUserService } from '../../Services/Fintechs/tapiUserService';
import { tapiService } from '../../Services/Fintechs/tapiService';

const OnSitePaymentByTapi = () => {
    const token = Common.getToken();
    const user = Common.getUser();
    const { id } = useParams();

    const [statePullapartBussinessId, setstatePullapartBussinessId] = useState(0);
    const [statePullapartTotalAmmount, setstatePullapartTotalAmmount] = useState(0);
    const [statePullAppartAmmount, setstatePullAppartAmmount] = useState(0);
    const [finalReference, setFinalReference] = useState("");

    useEffect(() => {
        const operationType = id.substring(0, 1);
        const refTmp = id.substring(1, id.length);
        if (operationType === Common.OperationTypeEnum.PullApart) {
            pullapartRefService.get(refTmp)
                .then((res) => {
                    const myArray = res.data.referencia.split("|");
                    setstatePullapartBussinessId(+myArray[1]);
                    setstatePullapartTotalAmmount(+myArray[4]);
                    setstatePullAppartAmmount(+myArray[6]);
                });
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencía no es para realizar un apartado!',
                footer: 'Favor de revisar los datos de las referencías'
            });
        }
        onClickBtnforCreatePullapartMethod()
    }, []);

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseclibUser = await tapiUserService.Authentication(userForAuthenticate);
        const tokenclib = responseclibUser.data.data.token;

        return tokenclib;
    }

    const onClickBtnforCreatePullapartMethod = async () => {
        if (!id){
            swal("Sucedió un error", "No se incluyó una referencia", "warning")
            .then((value) => {
                navigate(`/`, { replace: true });
            }); 
            return
        }
  
        const tokenClibfintech = await GetTokenFromApFintechs();
        const payload = {
            externalRequestId: "a5b508A88-0744-4a6d-ab68-099bf311facb",
            companyCode: "MX-S-00315",
            externalClientId: id.toString(),
            amountType: "OPEN",
            amount: statePullapartTotalAmmount + 10,
            currency: "MXN",
            generationData: [
                {
                    modalityId: "76a1a3f8-dca5-43ae-9d07-df3c8467582e",
                    identifierName: "ARXG-INV",
                    identifierValue: "null"
                },
                {
                    modalityId: "e8ba4e43-a010-44c9-8888-129fdde72103",
                    identifierName: "ARXF-INV",
                    identifierValue: "123456"
                }
            ],
            additionalData: [
                {
                    dataName: "email",
                    dataValue: user.user
                }
            ]
        }

        const responsePaymentMethod = await tapiService.generateReference(tokenClibfintech, payload);
        if(!responsePaymentMethod.isSuccess) {
            swal("Sucedió un error", "No pudimos generar tu referencia", "warning")
            .then((value) => {
                navigate(`/`, { replace: true });
            }); 
        } else {     
            setFinalReference(responsePaymentMethod.data.references[0].value)               
        }                   

    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className='col'></div>
                    <div className='col' style={{ textAlign: 'center' }}>
                        <h3>Pago de manera presencial</h3>
                    </div>
                    <div className='col'></div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className='alert alert-success' role="alert" style={{ textAlign: 'center' }}>
                            <h4>¿Cómo puedes pagar?</h4>
                            <p>Ve de manera presencial a cualquiera de los establecimientos y pide hacer un pago referenciado</p>
                            <p>Monto total: {Common.currencyFormat(statePullapartTotalAmmount)}</p>
                            <p>Apartado: {Common.currencyFormat(statePullAppartAmmount)}</p>
                            <p>Transacción: $10.00</p>
                            <p>Referencia: {finalReference}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {tapiCommerces.map((item) => {
                        return (
                            <div className="col-lg-4 col-sm-4 col-xs-6 d-flex justify-content-center align-items-center" key={item.name}>
                                <img src={item.logo} className='responsiveImageByWidth' />
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default OnSitePaymentByTapi;