import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { cxcService } from '../../Services/cxcService';
import { salesService } from '../../Services/salesService';
import { commerceService } from '../../Services/commerceService';
import { clibUserService } from '../../Services/Fintechs/clibUserService';
import { clibService } from '../../Services/Fintechs/clibService';
import { boromirUserService } from '../../Services/Boromir/boromirUserService';
import { boromirService } from '../../Services/Boromir/boromirService';

import { Common } from '../../Utils/Common';

import swal from 'sweetalert';

import { pullapartRefService } from '../../Services/pullApartReference';
import { array } from 'yup';

const PullapartByClip = () => {
    const token = Common.getToken();
    const user = Common.getUser();    

    const { id } = useParams();  

    const iframeRefChkclip = useRef(null);

    const [stateIframeVisible, setStateIframeVisible] = useState(true);

    const [statePullapartBussinessId, setstatePullapartBussinessId] = useState(0);
    const [statePullapartTotalAmmount, setstatePullapartTotalAmmount] = useState(0);
    const [statePullAppartAmmount, setstatePullAppartAmmount] = useState(0);

    useEffect(() => {
        CleanClipLocalStorageInfo();

        const operationType = id.substring(0, 1);			
		const refTmp = id.substring(1, id.length);
        if (operationType === Common.OperationTypeEnum.PullApart) {                
            pullapartRefService.get(refTmp)
            .then((res) => {                  
                //console.log(res.data.referencia);
                const myArray = res.data.referencia.split("|");            

                setstatePullapartBussinessId(+myArray[1]);
                setstatePullapartTotalAmmount(+myArray[4]);
                setstatePullAppartAmmount(+myArray[6]);

                SetLocalstorageVariables(+myArray[1]);
            });                             
        } else {                
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencía no es para realizar un apartado!',
                footer: 'Favor de revisar los datos de las referencías'
            });
        }         
    }, []); 

    const SetLocalstorageVariables = async (busId) => {        
        const commerceResponse = await commerceService.getAsync(token, busId); 

        //console.log(JSON.stringify(commerceResponse));

        if(commerceResponse.data.clipApiKey == null) {
            swal("Falta de configuración!", "No se pueden realizar pagos con tarjeta!", "warning")
            .then((value) => {
                CleanClipLocalStorageInfo();
                navigate(`/`, { replace: true });
            });
        } else {
            localStorage.setItem("PublickeyPA", commerceResponse.data.clipApiKey);        

            iframeRefChkclip.current.src = "/CheckoutclibV2.html";
        }           
    }

    const onClickBtnforCreatePullapartMethod = async (e) => {
        e.preventDefault();

        setStateIframeVisible(true);        

        const cardTokenClib = JSON.parse(localStorage.getItem("cardTokenClibPA"));

        if (cardTokenClib.id != ''){
            
            setStateIframeVisible(false);

            const tokenClibfintech = await GetTokenFromApFintechs();

            //console.log(tokenClibfintech);

            const payload =  {
                amount: statePullAppartAmmount + 15,
                currency: 'MXN',
                description: "Test Transparent checkout SDK PA",
                payment_method: {
                   token: cardTokenClib.id
                },
                customer: {
                    email: user.user,
                    phone: user.phone,
                    address: {
                        number: user.numberAddress,
                        postal_code: user.postalCodeAddress,        
                        street: user.streetAddress
                    }
                },
                axifyBusinnessId: statePullapartBussinessId
            };
            
            //console.log(JSON.stringify(tokenClibfintech));
            //console.log(JSON.stringify(payload));

            const responsePaymentMethod = await clibService.createPaymentsAsync(tokenClibfintech, payload);
                        
            //console.log(responsePaymentMethod);

            if(responsePaymentMethod.data.status == 'rejected') {
                swal("Transacción rechazada!", "Favor de reintentar el pago!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/`, { replace: true });
                }); 
            } else {
                if(responsePaymentMethod.data.status_detail.code == 'PE-3DS01') {
                    localStorage.setItem("pending_action.urlPA", responsePaymentMethod.data.pending_action.url); 
                    navigate(`/threeDSByclipPA/${id}/${statePullapartBussinessId}/${statePullAppartAmmount}`, { replace: true });                  
                } else {
                    await ApplyPullapartMethod(id, responsePaymentMethod.data.id, statePullapartBussinessId, statePullAppartAmmount);                  
                }
            }                   
        } else {
            swal("Error de tarjeta!", "fallo la generación de token de tarjeta!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/`, { replace: true });
                });            
        }    
    }

    const ApplyPullapartMethod = async (idP, paymentIdClipP, bussinessidP, importeP) => {
        const tokenClibfintech = await GetTokenFromApFintechs();
        const responsePaymentMethod = await clibService.getPaymentsAsync(tokenClibfintech, paymentIdClipP, bussinessidP);

        if(responsePaymentMethod.data.status == 'approved' && responsePaymentMethod.data.status_detail.code == 'AP-PAI01') {
            const userForAuthenticate = {
                "User": "soporte@axify.mx",
                "Password": "Axify2023"
            };

            let responseUserBoromir = await boromirUserService.postAuthenticate(userForAuthenticate);

            const tokentoboromir = responseUserBoromir.token;
            //console.log(tokentoboromir);

            const requestBoromirPullApart = {
                "referencia": idP,
                "importe": importeP,
                "compañia": '0',
                "sucursal": bussinessidP.toString(),
                "idtransaccion" : 'Clip|' + paymentIdClipP
            };

            //console.log(JSON.stringify(requestBoromirPayment));

            let responseBoromirPaymentService = await boromirService.postProductApart(tokentoboromir, requestBoromirPullApart);
                    
            //console.log(responseBoromirPaymentService);

            if (responseBoromirPaymentService.error == 0){
                swal("Transacción realizada!", "Gracias por su pago!", "success")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/`, { replace: true });
                });
    
            } else {
                swal("Abono no aplicado en axify!", "favor de contactar a soporte de axify!", "warning")
                .then((value) => {
                    CleanClipLocalStorageInfo();
                    navigate(`/`, { replace: true });
                });    
            }

        } else {
            swal("Transaccion rechazada!", "Favor de reintentar el pago!", "warning")
            .then((value) => {
                CleanClipLocalStorageInfo();
                navigate(`/`, { replace: true });
            });
        }
    }

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseclibUser = await clibUserService.Authentication(userForAuthenticate);
        
        //console.log(responseclibUser);
        
        const tokenclib = responseclibUser.data.data.token;

        return tokenclib;
    }  

    const CleanClipLocalStorageInfo = () => {
        localStorage.setItem("PublickeyPA", '');
        localStorage.setItem("cardTokenClibPA", '');
        localStorage.setItem("pending_action.urlPA", '');
    }

    return (
       <>
        <div className="container">
            <div className='row'>
                <div className='col'></div>
                <div className='col' style={{textAlign: 'center'}}>
                    <h3>Pago con tarjeta</h3>
                </div>
                <div className='col'></div>
            </div>            
            <div className="row">
                <div className="col"></div>
                <div className="col">
                    <div className='alert alert-success' role="alert" style={{textAlign: 'center'}}>                 
                            <p>Monto total: {Common.currencyFormat(statePullapartTotalAmmount)}</p>
                            <p>Apartado: {Common.currencyFormat(statePullAppartAmmount)}</p>                            
                            <p>Transacción: $15.00</p>                       
                    </div>
                </div>
                <div className="col"></div>
            </div>          
            <div className='row'>
                <div className='col'></div>
                <div className='col'>
                    <legend><img src="/assets/img/cliblogo.jpg" alt="Logo Clib" style={{ "width": "100px", "padding": "2px", "marginLeft": "40%" }}/></legend>                    
                </div>
                <div className='col'></div>
            </div>
            <div className='row' style={{ display: (stateIframeVisible ? 'block' : 'none')}}>
                <div className='col'></div>
                <div className='col'>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            {/* <iframe ref={iframeRefChkclip} title="CheckoutclibV1" src="/CheckoutclibV1.html" style={{border:"none", width:"500px", height:"400px"}}></iframe>  */}
                            <iframe ref={iframeRefChkclip} title="CheckoutclibV2" src="" style={{border:"none", width:"500px", height:"400px"}}></iframe> 
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className='col'></div>
            </div>           
            <div className="row">                   
                <div className='col'>
                    <button id="btnForCreatePullApartMethod" onClick={e => onClickBtnforCreatePullapartMethod(e)} style={{ display: "none" }}>Process</button>                    
                </div>             
            </div>
        </div>            
       </>         
    )

}

export default PullapartByClip;