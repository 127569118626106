import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'

import { cxcService } from '../../Services/cxcService';

import PopUpCancelSale from './PopupCancel';

import { Common }from '../../Utils/Common'

const CxcList = () => {
    const auth = Common.getUser();    

    const navigate =  useNavigate(); 

    const [stateSeenPopup, setstateSeenPopup] = useState(false);
    const [stateSelectedSale, setstateSelectedsale] = useState(0);
    const [stateShopping, setstateShopping] = useState([]);   
    
    useEffect(() => {
        //console.log(auth);
        setTimeout(() => {            
            ExecuteGetPullApart();               
        }, 1000);        
    }, []);    
  
    const onClickDOMEvent_btnVisCxc = (e, ventaid)=> {
        e.preventDefault();       

        try {
          navigate(`/saleCxcList/${ventaid}`, { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }
    
    const onClickDOMEvent_btnCancelSale = (e, ventaid) => {     
        setstateSelectedsale(ventaid);   
        setstateSeenPopup(true);
    }

    const togglePop = (option) => {
        setstateSeenPopup(!stateSeenPopup);    
    };

    const CalcProgressBySale = (numTotal, numPartial) => {
        const valueTmp = (numPartial * 100) / numTotal;

        return `${valueTmp}%`;
    }

    const GettitleSale = (pStatus, pNum1, pNum2) => {
        let strTitle  ='';
        if(pStatus == 1 && (pNum1 < pNum2)){
            strTitle = 'Activa';
        } 
        if(pStatus == 1 && (pNum1 == pNum2)){
            strTitle='Liquidada';
        }
        if(pStatus == 2){
            strTitle='Cancelada';
        }
        return strTitle;
    }

    const ExecuteGetPullApart = () => {
        cxcService.getByBuyertId(auth.id).then( res => {            
            setstateShopping(res.data);                
        }); 
    }

    return (
        <>
            <br></br>
            <br></br>            
            <div className='container'>                                
                <div className='row'>
                    <div className='col'>
                        <h2>Listado de compras en Axify</h2>
                    </div>
                 
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col-12'>
                        {stateShopping.length == 0  ? 
                            <div className="alert alert-warning" role="alert">
                                Tu listado de compras está vacio!
                                Para realizar un apartado toma foto del código QR del producto o servicio y después inicia sesión.
                            </div> : null
                        }
                        <ul className="list-group">
                        {
                            stateShopping && stateShopping?.map(item =>
                                <li className="list-group-item" key={item.ventaId}>
                                    <div className='row'>
                                        <div className='col'>
                                            <h2>{GettitleSale(item.estatusVentaId, item.numCxcPagadas, item.numCxc)}</h2>
                                            <p>Compra : {item.ventaId}</p>
                                            <p>{moment(item.fechaVta).format('MMMM DD YYYY')} </p>                                           
                                            <p>Sucursal: {item.nombreComercial}</p>                     
                                            <p>Cliente: {item.nombre}</p>                                                                   
                                            <p>{item.descripcion}</p>                                            
                                        </div>
                                        
                                        <div className='col' style={{textAlign: 'right'}}>
                                            <br></br>
                                            <br></br>
                                            <p>Monto: {Common.currencyFormat(item.montoVenta)}</p>
                                            <p>Adelanto: {Common.currencyFormat(item.montoAdelanto)}</p>
                                            <p>$ pagos : {Common.currencyFormat(item.totalPagos)}</p>
                                            <p># pagos : {item.numCxc}</p>                                            
                                            <p># pagados: {item.numCxcPagadas}</p>        
                                            
                                            <a className="btn btn-info" onClick={e => onClickDOMEvent_btnVisCxc(e,item.ventaId)} style={{cursor:"pointer"}}>Visualizar cxc</a>
                                            <br></br>
                                            {
                                            (item.estatusVentaId == 1 && (item.numCxcPagadas < item.numCxc)) ?
                                                <a className="btn btn-danger" onClick={e => onClickDOMEvent_btnCancelSale(e,item.ventaId)} style={{cursor:"pointer"}}>Canc.Compra</a>
                                                : null                                                      
                                            }
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: CalcProgressBySale(item.numCxc, item.numCxcPagadas)}}>
                                                    {CalcProgressBySale(item.numCxc, item.numCxcPagadas)}
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </li>
                            )
                        }
                        </ul>
                    </div>
                </div>              
            </div>    

            {stateSeenPopup ? <div> <PopUpCancelSale              
                                    saleId={stateSelectedSale}                                                           
                                    togglePop={ togglePop } 
                                    ExecuteGetPullApart={ExecuteGetPullApart}
                                /> 
                            </div> : null}       


        </>
    );

};

export default CxcList;