import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_FINTECHS_URL}/v1/Clib`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
}; 

const getPaymentsAsync = async (auth_token_clibfintech, paymentId, bussinessId) => { 

    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token_clibfintech}`;

    const objRequest = await axios.get(`${API_BASE_URL}/GetPaymentsAsync/${paymentId}/${bussinessId}`, CONFIG_REQUEST);
    const response = objRequest.data;

    return response;
}

const createPaymentsAsync = async (auth_token_clibfintech, values) => { 

    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token_clibfintech}`;

    const objRequest = await axios.post(`${API_BASE_URL}/CreatePaymentsAsync`, values, CONFIG_REQUEST);
    const response = objRequest.data;

    return response;
}


export const clibService = {
    getPaymentsAsync,
    createPaymentsAsync
}